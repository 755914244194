function DoAThing(){
    //Turns out this is un-needed
    // alert("Hello");
}
const Listings = () => {
    
    return (
        <div className="hero w-full h-screen pt-[80px] overflow-hidden">
            
            <iframe title='listings' onLoad={DoAThing} className="overflow-hidden" height="1000" width="100%" frameborder="0" src="https://tours.tourfactory.com/results.asp?u=481&amp;home=www.pamfredrick.com&amp;p=1">your browser does not support IFRAMEs</iframe>
            
        </div>
    )
  };
  
  export default Listings;