import { Divide as Hamburger } from 'hamburger-react';
import { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { Link, useLocation } from 'react-router-dom';
import logo from '../images/JLSRealEstate_W.gif';

// function MobileMenu(props) {
//     const linkanim = useAnimationControls();
    
    
//     if (props.isOpen) {
//       return (
            
//         );
//     }
//     return <div />;
//   }
export default function Navbar(){
    const [isOpen, setOpen] = useState(false);
    const {pathname} = useLocation();

    useEffect(() => {
      setOpen(false); // Close the navigation panel
    }, [ pathname ]);
    

    return(
        <div className="bg-emerald-900/75 text-white fixed top-0 w-full z-10">
            <div className="p-5 flex justify-between w-full font-bold items-center lg:px-20">
                <Link to="/">
                    <motion.div
                initial={{ y: "-100%", opacity: 0 }}
                animate={{ y: "0", opacity: 1 }}
                transition={{ delay: 0, duration: 0.5 }} className="font-dancingscript text-4xl inline"><img className='h-[40px]' src={logo} width="auto" alt="Logo" /></motion.div>
                {/* <motion.div
                initial={{ y: "-100%", opacity: 0}}
                animate={{ y: "0", opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }} className="font-dancingscript text-4xl inline"> Fredrick </motion.div> */}
                </Link>
                <div className="desktop-nav hidden lg:flex md:hidden items-center gap-6">
                <motion.div
                initial={{ opacity: 0, y: "-100%" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ delay: 0, duration: 0.5 }}><Link to="/">Home</Link></motion.div>
                <motion.div
                initial={{ opacity: 0, y: "-100%" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ delay: 0.2, duration: 0.5 }}><Link to="/about">About</Link></motion.div>
                    <motion.div
                initial={{ opacity: 0, y: "-100%" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ delay: 0.4, duration: 0.5 }}><Link to="/listings">Listings</Link></motion.div>
                    <motion.div
                initial={{ opacity: 0, y: "-100%" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ delay: 0.6, duration: 0.5 }}><Link to="/contact">Contact</Link></motion.div>
                <motion.div
                initial={{ opacity: 0, y: "-100%" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ delay: 0.8, duration: 0.5 }}><Link to="/testimonials">Testimonials</Link></motion.div>
                </div>
                <div className="mobile-nav hidden max-lg:block items-center gap-6">
                    <Hamburger toggled={isOpen} toggle={setOpen} />

                </div>
            </div>
            {/* <MobileMenu isOpen={isOpen} /> */}
            {isOpen && (
                <motion.div
                initial={{ y: "-100%" }}
                animate={{ y: "0" }}
                transition={{ delay: 0 }}
                className="p-5 flex justify-between w-full font-bold items-center">
                <ul className='flex justify-center items-center flex-col gap-6 w-full text-xl font-bold'>
                <li><motion.div
                initial={{ opacity: 0, y: "-100%" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ delay: 0, duration: 0.5 }}><Link to="/">Home</Link></motion.div></li>
                    <li><motion.div
                initial={{ opacity: 0, y: "-100%" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ delay: 0.2, duration: 0.5 }}><Link to="/about">About</Link></motion.div></li>
                    <li><motion.div
                initial={{ opacity: 0, y: "-100%" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ delay: 0.4, duration: 0.5 }}><Link to="/listings">Listings</Link></motion.div></li>
                    <li><motion.div
                initial={{ opacity: 0, y: "-100%" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ delay: 0.6, duration: 0.5 }}><Link to="/contact">Contact</Link></motion.div></li>
                <li><motion.div
                initial={{ opacity: 0, y: "-100%" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ delay: 0.8, duration: 0.5 }}><Link to="/testimonials">Testimonials</Link></motion.div></li>
                </ul>
            </motion.div>
            )}
        </div>
        
    )
}