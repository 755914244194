
import bg from '../images/202210716-6.jpeg';
import { Link } from 'react-router-dom';
import Accordion from '../components/Accordion';
import Chevron from '../components/Chevron';
import ContactForm from '../components/ContactForm';
import { motion } from 'framer-motion';

const Faq = () => {
    return (
        <div className='w-full flex flex-col justify-center items-center pt-[88px]'>
            <motion.img initial={{ opacity: 0.1 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className='h-screen w-screen object-cover grayscale brightness-50 fixed top-0 left-0 -z-50' src={bg} width="auto" alt="luxury home background" />
            {/* <img className='w-full h-[200px] object-cover' src={bust} width="auto" alt="Bust" /> */}
            <div className='p-8 rounded-lg bg-emerald-900 mt-[10px] text-white font-bold mx-4 lg:w-1/2'>
                <Accordion
                    title="Considering Buying? Start here!"
                    content="Be a smart consumer.  Learn the financing basics. Know how to shop for a home loan that's right for you. Get pre-approved.  This takes very little time and lets you know the price range that fits your lifestyle. Know what you want. The last thing you need is to close a deal and realize you bought a house you don't want. Ask yourself what you're looking for in a home, before you shop. Think about size, commute time and necessary repairs. Keep your debt load to a minimum.  Don't make major purchases or incur any additional debt until after your purchase. Pay down credit cards and don't apply for new ones. Remember, financial institutions evaluate your financial situation on your gross monthly income. Your total monthly house costs should not exceed 28 percent of your gross monthly income. Be prepared to view new properties quickly. Sometimes homes sell quickly, so be ready to make fast decisions. Be accessible to change the terms. Have instant access to your agent. Instant communication can mean the difference in purchasing the property of your choice."
                />
                <Accordion
                    title="Before you buy:"
                    content="Submit a strong competitive offer. Include a substantial earnest money deposit. Sometimes offers are accepted based on the amount of the deposit. Try to minimize the number of contingencies. Fewer contingencies mean a stronger offer. Hire an inspector.  A professional building inspector or appraiser will make sure the house of your choice is in satisfactory condition. Check zoning regulations and covenants. Good residential neighborhoods will be zoned to keep out commercial and industrial users. Read any restrictive covenants and make sure they fit your lifestyle. Request an updated property survey.  Be sure it clearly marks boundaries. Check for problems. Make sure you know what stays or goes.Your contract should be very specific about which items (appliances, etc) are included in the sale. Get agreements in writing. Make certain verbal agreements are written into the final contract to avoid any stressful and expensive issues later."
                />
                {/* <p className='pb-4 text-center font-bold text-2xl'>
                    Considering Buying?
                </p>
                <p className='pb-4 text-center'>
                    Be a smart consumer.  Learn the financing basics. Know how to shop for a home loan that's right for you. Get pre-approved.  This takes very little time and lets you know the price range that fits your lifestyle. Know what you want. The last thing you need is to close a deal and realize you bought a house you don't want. Ask yourself what you're looking for in a home, before you shop. Think about size, commute time and necessary repairs. Keep your debt load to a minimum.  Don't make major purchases or incur any additional debt until after your purchase. Pay down credit cards and don't apply for new ones. Remember, financial institutions evaluate your financial situation on your gross monthly income. Your total monthly house costs should not exceed 28 percent of your gross monthly income. Be prepared to view new properties quickly. Sometimes homes sell quickly, so be ready to make fast decisions. Be accessible to change the terms. Have instant access to your agent. Instant communication can mean the difference in purchasing the property of your choice.
                </p> */}
                {/* <p className='pb-4 text-center font-bold text-2xl'>
                    Before you buy:
                </p>
                <p className='pb-4 text-center'>
                    Submit a strong competitive offer. Include a substantial earnest money deposit. Sometimes offers are accepted based on the amount of the deposit. Try to minimize the number of contingencies. Fewer contingencies mean a stronger offer. Hire an inspector.  A professional building inspector or appraiser will make sure the house of your choice is in satisfactory condition. Check zoning regulations and covenants. Good residential neighborhoods will be zoned to keep out commercial and industrial users. Read any restrictive covenants and make sure they fit your lifestyle. Request an updated property survey.  Be sure it clearly marks boundaries. Check for problems. Make sure you know what stays or goes.Your contract should be very specific about which items (appliances, etc) are included in the sale. Get agreements in writing. Make certain verbal agreements are written into the final contract to avoid any stressful and expensive issues later.
                </p> */}
                <Link to="/listings"><div className='p-2 rounded bg-rose-600 text-white text-center my-4 hover:scale-110 ease-in-out duration-300'>View Pam's Listings Now! <Chevron className="inline" width={10} fill={"#fff"} /></div></Link>
                <Accordion
                    title="Considering Selling?"
                    content="Choosing your real estate professional is, perhaps, the most important decision you will make when selling property. We believe the more you look around, the more you will see the value of working with me. However, no matter whom you choose, these next simple tips will help you sell your home more quickly and efficiently."
                />
                <Accordion
                    title="Seller Tips"
                    content="Make first impressions count.   Without a doubt, a visually appealing house will attract buyers, who can't help but respond to the look and 'feel' of a home. Take time to carefully prepare for showings. Don't forget the following:<br />-Cut the grass<br />-Remove any clutter from the yard<br />-Trim hedges<br />-Trim hedges<br />-Weed gardens<br />-Wash steps, windows, railings, doors, etc<br />-Paint if needed<br />-Remove unnecessary clutter from garages<br />Scrub, dust and fix up the works.  Buyers will notice details. Get rid of the clutter, repair leaky faucets, wage war on dust and clean until your home shines. Small things can make a potential buyer walk away."
                />
                <Accordion
                    title="Prepare for Showing"
                    content="When you prepare your house for showing, remember to:<br/>-Shampoo carpets<br/>-Clean tubs, toilets and showers and hang fresh towels<br/>-Oil squeaky doors<br/>-Fix things like broken hinges and light switches<br/>Listen to suggestions. As you prepare your home, don't rely solely on your own judgment. It's hard to be objective when you're the owner. Your realtor will have helpful, professional tips on how to make your home more marketable. Take a whiff. Nothing will turn a buyer away faster than an odd smell. Try to eliminate smoking, food and pet odors. And don't leave any clues. If potential buyers see a dog or an ashtray, they'll be on the lookout for smells and stains. Turn on the lights. Open shades and draperies before a viewing. Open all doors inside home. Turn on inside and outside lights. Let potential buyers 'see' themselves in your home. Too many personal items can make viewers feel like they're intruding in someone else's home. Keep things clean and simple. Decorate in neutral colors. Get out of the house. When buyers view your home, they'll be more comfortable and spend more time if you're not there. If you must be present, be as unobtrusive as possible. Let your agent do the work. Stay unemotional during negotiations. Selling your home can be emotionally charged, but don't let that stand in the way of making a deal. Have a business-like attitude during the process."
                />
                {/* <p className='pb-4 text-center font-bold text-2xl'>
                    Considering Selling?
                </p>
                <p className='pb-4 text-center'>
                    Choosing your real estate professional is, perhaps, the most important decision you will make when selling property. We believe the more you look around, the more you will see the value of working with me. However, no matter whom you choose, these simple tips will help you sell your home more quickly and efficiently.
                </p>
                <p className='pb-4 text-center font-bold text-2xl'>
                    Seller Tips
                </p>
                <p className='pb-4 text-center'>
                Make first impressions count.   Without a doubt, a visually appealing house will attract buyers, who can't help but respond to the look and "feel" of a home. Take time to carefully prepare for showings. Don't forget the following:
                </p>
                <ul className='pb-4 list-disc'>
                    <li className='pl-4'>Cut the grass</li>
                    <li className='pl-4'>Remove any clutter from the yard</li>
                    <li className='pl-4'>Trim hedges</li>
                    <li className='pl-4'>Weed gardens</li>
                    <li className='pl-4'>Wash steps, windows, railings, doors, etc</li>
                    <li className='pl-4'>Paint if needed</li>
                    <li className='pl-4'>Remove unnecessary clutter from garages</li>
                </ul>
                <p className='pb-4 text-center'>Scrub, dust and fix up the works.  Buyers will notice details. Get rid of the clutter, repair leaky faucets, wage war on dust and clean until your home shines. Small things can make a potential buyer walk away. When you prepare your house for showing, remember to:</p>
                <ul className='pb-4 list-disc'>
                    <li className='pl-4'>Shampoo carpets</li>
                    <li className='pl-4'>Clean tubs, toilets and showers and hang fresh towels</li>
                    <li className='pl-4'>Oil squeaky doors</li>
                    <li className='pl-4'>Fix things like broken hinges and light switches</li>
                </ul>
                <p className='pb-4 text-center'>
                Listen to suggestions. As you prepare your home, don't rely solely on your own judgment. It's hard to be objective when you're the owner. Your realtor will have helpful, professional tips on how to make your home more marketable. Take a whiff. Nothing will turn a buyer away faster than an odd smell. Try to eliminate smoking, food and pet odors. And don't leave any clues. If potential buyers see a dog or an ashtray, they'll be on the lookout for smells and stains. Turn on the lights. Open shades and draperies before a viewing. Open all doors inside home. Turn on inside and outside lights. Let potential buyers "see" themselves in your home. Too many personal items can make viewers feel like they're intruding in someone else's home. Keep things clean and simple. Decorate in neutral colors. Get out of the house. When buyers view your home, they'll be more comfortable and spend more time if you're not there. If you must be present, be as unobtrusive as possible. Let your agent do the work. Stay unemotional during negotiations. Selling your home can be emotionally charged, but don't let that stand in the way of making a deal. Have a business-like attitude during the process.
                </p> */}
            </div>
            <div className='p-8 rounded-lg bg-emerald-900 mt-[10px] text-white font-bold  lg:w-1/2 mx-4 '>
                <p className='text-xl'>Contact Info</p>
                <p>Email: <a className='text-rose-600' href='mailto:pamfredrick@johnlscott.com'>pamfredrick@johnlscott.com</a></p>
                <p>Phone: <a className='text-rose-600' href="tel:509.924.4200">509-924-4200</a></p>
                <p className='text-xl mt-6'>Or Use Our Form!</p>
                <ContactForm />

                <p className='text-xs mt-[100px]'>copyright 2023. pamfredrick.com</p>
            </div>
        </div>
    )
  };
  
  export default Faq;