
import bg from '../images/testimonials.jpg';
import { Link } from 'react-router-dom';
import Chevron from '../components/Chevron';
import { motion } from 'framer-motion';

const Testimonials = () => {
    return (
        <div className='w-full flex flex-col justify-center items-center pt-[88px]'>
            <motion.img initial={{ opacity: 0.1 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className='h-screen w-screen object-cover grayscale brightness-50 fixed top-0 left-0 -z-50' src={bg} width="auto" alt="luxury home background" />
            <div className='p-8 rounded-lg bg-emerald-900 mt-[10px] text-white font-bold mx-4 lg:w-1/2'>
                <p className='text-sm text-slate-100 leading-6 italic'><span className='text-xl not-italic'>"</span>All too often expert, professional and committed customer service is overlooked, yet it is a rarity in today's environment. I would sincerely like to thank you and Gina, and your entire professional team for the outstanding experience that I had with you. Your expertise is unparalleled, coupled with the sensitivity to my unique situation was more than I could have asked for. Your willingness to help and support the entire process from beginning to end was extraordinary as was you and your team's communication skills. Again, THANK YOU! This is one step closer to a new beginning for Isa and me. I would not hesitate to recommend you and your fantastic team to anyone!<br/>Thank you so very much!<span className='text-xl not-italic'>"</span></p>
                <p className='text-slate-100 font-bold italic mt-4'>-Jennifer Hall</p>
                <Link to="/listings"><div className='p-2 rounded bg-rose-600 text-white text-center my-4 hover:scale-110 ease-in-out duration-300'>View Pam's Listings Now! <Chevron className="inline" width={10} fill={"#fff"} /></div></Link>
            </div>
            <div className='p-8 rounded-lg bg-emerald-900 mt-[10px] text-white font-bold mx-4 lg:w-1/2'>
                <p className='text-sm text-slate-100 leading-6 italic'><span className='text-xl not-italic'>"</span>During the Summer, my wife and I considered relocating to the Spokane area. We had numerous criteria regarding the types of homes we would prefer to view, along with several misperceptions regarding the services of a realtor. Our misperceptions included the following?
                <ul className='list-disc text-xs pl-4 py-4'>
                    <li className='mb-2'>Could an agent receiving a fee based on the transaction amount ever genuinely represent the buyer's interests?</li>
                    <li className='mb-2'>Should a prospective buyer ever view a home with someone other than the listing agent {"("}obviously fee negotiation could be more fruitful with the listing agent{")"}?</li>
                    <li>Would it be wise for a buyer to commit to one realtor, and allow that person to guide them through the entire search and transaction?</li>
                </ul>

                Imagine our pleasant surprise to find the answers to these questions - yes, sure and yes as long as the agent in Pam Fredrick. Compared to many buyers, we had a fairly short time to view homes. Through Pam we arranged a Thursday, Friday, and Saturday when we would be visiting the area to see several homes. Pam was a diligent in identifying homes we might like to see well in advance, and had a carefully planned "grand tour" of available homes in our price range and meeting our desired criteria. We then revisited our selected list for a second time on Saturday. {"("}Thanks for setting aside your entire weekend Pam!{")"} As "out-of-towners" we relied on Pam to help us put together all of the offer material via fax and email. She then walked us through the process by completely handling all the negotiations with the seller {"("}our transaction had some complications as to when the seller would vacate the premises and what amount would be paid at various stages of the transaction{")"}. All of this while we were living in another State! After the transaction Pam even purchased a small insurance policy covering potential home repairs we might need during our first year of ownership. She has continued to keep in touch long after closing. In short-Pam was excellent! Many thanks to her for guiding us through the purchase of our dream home!<span className='text-xl not-italic'>"</span></p>
                <p className='text-slate-100 font-bold italic mt-4'>-David Pointer</p>
            </div>
            <div className='p-8 rounded-lg bg-emerald-900 mt-[10px] text-white font-bold mx-4 lg:w-1/2'>
                <p className='text-sm text-slate-100 leading-6 italic'><span className='text-xl not-italic'>"</span>When Pam first met with us we were a bit intimidated. Her experience and qualifications were admirable. The majority of her clients were buying and/or selling high priced, luxury homes. We were concerned that our listing would not get much attention due to the lower commission that the sale would produce. Pam assured us that she gave the same high quality, personal service to all her clients, regardless of commission potential. Wow, was she right. Pam did an exemplary job of marketing and selling our home. The service we received was so prompt and personal it often felt as if we were Pam's only clients! She happily answered all our questions (we had alot) and patiently fulfilled any request. It was our first home selling experience and we are extremely grateful that Pam was with us every step of the way. We won't hesitate to recommend Pam Fredrick to anyone we know who is buying and/or selling a home. It has been a pleasure working with your company.<span className='text-xl not-italic'>"</span></p>
                <p className='text-slate-100 font-bold italic mt-4'>-Laurie and Eric Peterson</p>
            </div>
            <div className='p-8 rounded-lg bg-emerald-900 mt-[10px] text-white font-bold mx-4 lg:w-1/2 mb-4'>
                <p className='text-sm text-slate-100 leading-6 italic'><span className='text-xl not-italic'>"</span>I would like to take this opportunity to thank you for the Latah Creek bottle of wine you delivered to my office today, and also for your very kind note. You indicate in your greetings letter that you are number six in sales volume in Spokane. Notwithstanding the sales volume numbers, as far as I am concerned you are number one. I would also like to thank you and your staff for all of your assistance and effort in completing the lot sales at the Ridgeview Estates Subdivision. I am certain without your expertise we would still have lots for sale. All the best to you and your staff, and my you have a very successful prosperous New Year.<span className='text-xl not-italic'>"</span></p>
                <p className='text-slate-100 font-bold italic mt-4'>-John T Peterson</p>
            </div>
        </div>
    )
  };
  
  export default Testimonials;