import videoBG from '../PamFrederick_bg_final_720p.mp4';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import topone from '../images/Top1_White_2021.png';
import lifetime from '../images/Top1Lifetime_White.png';
import luxury from '../images/Luxury_Portfolio_White.png';
import Chevron from '../components/Chevron';

const Home = () => {
    return (
        <div className="hero w-full h-full">
        <video className="w-full h-screen object-cover" src={videoBG} autoPlay loop muted />
        <div className='overlay absolute top-0 left-0 w-full h-full'>
          <div className='w-full h-full flex flex-col justify-center items-center bg-neutral-900/50 px-20 text-center font-bold gap-6'>
            <motion.div
                initial={{ y: "-100%", opacity: 0 }}
                animate={{ y: "0", opacity: 1 }}
                transition={{ delay: 0, duration: 0.5 }} className="font-dancingscript text-5xl text-white inline leading-9">Pam </motion.div> <motion.div
                initial={{ y: "-100%", opacity: 0 }}
                animate={{ y: "0", opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }} className="font-dancingscript text-5xl text-white inline leading-9">Fredrick, </motion.div> <motion.div
                initial={{ y: "-100%", opacity: 0 }}
                animate={{ y: "0", opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }} className="font-dancingscript text-5xl text-white inline leading-9">Real Estate </motion.div> <motion.div
                initial={{ y: "-100%", opacity: 0 }}
                animate={{ y: "0", opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }} className="font-dancingscript text-5xl text-white inline leading-9">Broker</motion.div>
            <p className='text-white '>Spokane, Washington</p>
            <div className='w-full flex justify-center gap-6'>
                <img className='h-[75px]' src={topone} width="auto" alt="Logo" />
                
                <img className='h-[75px]' src={lifetime} width="auto" alt="Logo" />
            </div>
            <img className='h-[75px]' src={luxury} width="auto" alt="Logo" />
            <div className='mt-6'>
              <Link to="/listings" className='rounded text-white bg-rose-600 text-center font-bold p-4 hover:scale-125 ease-in-out duration-300'>View Listings <Chevron className="inline" width={10} fill={"#fff"} /></Link>
            </div>
          </div>
        </div>
        {/* <div className='absolute bottom-8 right-4'>
          <a className="" href="tel:555.555.5555">
            <div className='bg-emerald-600 rounded-full flex justify-center items-center w-[50px] h-[50px]'>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ffffff" viewBox="0 0 25 25">
                <path d="M24.1 18.172l-5.109-2.635c-.71-.369-1.606-.185-2.106.448l-1.87 2.397c-1.817-.974-3.213-1.923-4.82-3.53-1.71-1.713-2.633-3.136-3.58-4.9l2.37-1.845c.631-.5.842-1.397.447-2.108L6.8.914C6.325.018 5.192-.272 4.376.28l-3.24 2.16C.374 2.944-.047 3.813.005 4.709.057 5.63.19 6.789.4 7.606c.948 3.636 3.213 7.404 6.4 10.592 3.186 3.188 6.952 5.454 10.586 6.403.816.21 1.975.316 2.923.395.922.053 1.79-.395 2.291-1.16l2.107-3.293c.58-.817.263-1.923-.606-2.371z"></path>
              </svg>
            </div>
              
          </a>
        </div> */}
      </div>
    )
  };
  
  export default Home;