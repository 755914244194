
import bust from '../images/pam_fredrick_bust_jls.png';
import bg from '../images/testimonials.jpg';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Chevron from '../components/Chevron';

const About = () => {
    return (
        
        <div className='w-full flex flex-col justify-center items-center pt-[80px] p-4 overflow-x-hidden'>
            <motion.img initial={{ opacity: 0.1 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                className='h-screen w-screen object-cover grayscale brightness-50 fixed top-0 left-0 -z-50' src={bg} width="auto" alt="spokane background scenery" />
            <div className='absolute top-[120px] left-4 w-16 md:w-32 lg:w-48 lg:left-1/4 z-1'>
            <motion.div
                initial={{ y: "-100%", opacity: 0 }}
                animate={{ y: "0", opacity: 1 }}
                transition={{ delay: 0 }} className="text-3xl lg:text-4xl text-white inline leading-9 duration-300 lg:font-dancingscript">Professional </motion.div> <motion.div
                initial={{ y: "-100%", opacity: 0 }}
                animate={{ y: "0", opacity: 1 }}
                transition={{ delay: 0.4 }} className="text-3xl lg:text-4xl text-white inline leading-9 duration-300 lg:font-dancingscript">Service </motion.div> <motion.div
                initial={{ y: "-100%", opacity: 0 }}
                animate={{ y: "0", opacity: 1 }}
                transition={{ delay: 0.8 }} className="text-3xl lg:text-4xl text-white inline leading-9 duration-300 lg:font-dancingscript">With A Personal </motion.div> <motion.div
                initial={{ y: "-100%", opacity: 0 }}
                animate={{ y: "0", opacity: 1 }}
                transition={{ delay: 1.2 }} className="text-3xl lg:text-4xl  text-white inline leading-9 duration-300 lg:font-dancingscript">Touch</motion.div>
            </div>
            {/* <div className='z-1'>
                
            </div> */}
            <motion.img initial={{ opacity: 0.1 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }} className='-z-10 lg:h-96' src={bust} width="auto" alt="Bust" />
            <div className='p-8 rounded-lg bg-emerald-900 mt-[-100px] text-white font-bold lg:w-1/2'>
                <p className='pb-4 text-center'>
                    Pam is a Real Estate Broker and Residential Marketing Specialist at John L. Scott. She leads Spokane and the surrounding area in exclusive marketing and sales of luxury homes and is currently rated as one of Spokane’s top 5 producers. Pam has successfully earned the “Realtor Excellence Award” for the past 12 years and is a member of the 15 million Dollar Club. She has also earned the prestigious Top 1% Designation, and the “Presidential Elite” Award. The Spokane Association of Realtors has awarded her with the exclusive membership in the Residential Top 10 for the years 2003 – 2022. Pam is ranked as the #1 Listing and Selling Agent in Liberty Lake and Spokane Valley, and ranked Top 5 in Spokane County in Residential Re-Sale.
                </p>
                <Link to="/listings"><div className='p-2 rounded bg-rose-600 text-white text-center mb-4 hover:scale-110 ease-in-out duration-300 '>View Pam's Listings Now! <Chevron className="inline" width={10} fill={"#fff"} /></div></Link>
                <p className='pb-4 text-center'>
                    Pam’s success and reputation, within the industry and among her loyal clients, demonstrates her commitment to upholding high standards and professional ethics. These genuine qualities and her personal dedication are the traits that foster the return of her clients time and time again.
                </p>
                <p className='pb-4 text-center'>
                    Many factors influence the sale of your property…hiring an experienced, dedicated, and hardworking Realtor is the most important. Pam continually provides the best possible marketing and customer service to ensure your property get the attention it deserves. Pam’s constant and tireless effort to achieve success, along with her professionalism, is a winning combination.
                </p>
                <p className='text-center'>
                    Pam and her husband John, have been residents in Spokane for over 30 years. Pam, a licensed Realtor for over 20 years has earned the trust and respect of her co-workers and thousands of Spokane homeowners, merchants, and lenders. She is the best in her field and dedicated to getting the job done!
                </p>
            </div>
        </div>
    )
  };
  
  export default About;