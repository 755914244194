import {useState} from 'react';
import { motion
 } from "framer-motion";
import ContactForm from "./ContactForm";
export default function Footer(){
    const [contactShow, setContactShow] = useState(false);
    return(
        <>
        <motion.div 
        initial={{scale:1}}
        animate={{scale:[1, 1.2, 1]}}
        transition={{repeat:15, delay: 15}} className='fixed bottom-8 right-4 md:hidden'>
            <a className="" href="tel:509.924.4200">
                <div
                className='bg-emerald-600 rounded-full flex justify-center items-center w-[50px] h-[50px]'>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ffffff" viewBox="0 0 25 25">
                    <path d="M24.1 18.172l-5.109-2.635c-.71-.369-1.606-.185-2.106.448l-1.87 2.397c-1.817-.974-3.213-1.923-4.82-3.53-1.71-1.713-2.633-3.136-3.58-4.9l2.37-1.845c.631-.5.842-1.397.447-2.108L6.8.914C6.325.018 5.192-.272 4.376.28l-3.24 2.16C.374 2.944-.047 3.813.005 4.709.057 5.63.19 6.789.4 7.606c.948 3.636 3.213 7.404 6.4 10.592 3.186 3.188 6.952 5.454 10.586 6.403.816.21 1.975.316 2.923.395.922.053 1.79-.395 2.291-1.16l2.107-3.293c.58-.817.263-1.923-.606-2.371z"></path>
                </svg>
                </div>
                
            </a>
        </motion.div>
        <motion.div 
        initial={{scale:1}}
        animate={{scale:[1, 1.2, 1]}}
        transition={{repeat:14, delay: 16}} className='fixed bottom-8 left-4 md:hidden'
        onClick={()=>{
            setContactShow(!contactShow)
        }}>
                <div
                className='bg-emerald-600 rounded-full flex justify-center items-center w-[50px] h-[50px] p-2'>
                <svg fill="#ffffff" viewBox="0 0 75 75">
                <g>
                    <path d="M66.097,12.089h-56.9C4.126,12.089,0,16.215,0,21.286v32.722c0,5.071,4.126,9.197,9.197,9.197h56.9
                        c5.071,0,9.197-4.126,9.197-9.197V21.287C75.295,16.215,71.169,12.089,66.097,12.089z M61.603,18.089L37.647,33.523L13.691,18.089
                        H61.603z M66.097,57.206h-56.9C7.434,57.206,6,55.771,6,54.009V21.457l29.796,19.16c0.04,0.025,0.083,0.042,0.124,0.065
                        c0.043,0.024,0.087,0.047,0.131,0.069c0.231,0.119,0.469,0.215,0.712,0.278c0.025,0.007,0.05,0.01,0.075,0.016
                        c0.267,0.063,0.537,0.102,0.807,0.102c0.001,0,0.002,0,0.002,0c0.002,0,0.003,0,0.004,0c0.27,0,0.54-0.038,0.807-0.102
                        c0.025-0.006,0.05-0.009,0.075-0.016c0.243-0.063,0.48-0.159,0.712-0.278c0.044-0.022,0.088-0.045,0.131-0.069
                        c0.041-0.023,0.084-0.04,0.124-0.065l29.796-19.16v32.551C69.295,55.771,67.86,57.206,66.097,57.206z"/>
                </g>
                </svg>
                </div>
        </motion.div>
        <motion.div initial={{scale:1}}
        animate={{scale:[1, 1.2, 1]}}
        className={contactShow?'fixed bottom-0 right-0 left-0 top-0 z-10 h-full bg-stone-900/90 p-4 flex items-center justify-center duration-300 opacity-100 pointer-events-auto':'fixed bottom-0 right-0 left-0 top-0 z-10 h-full bg-stone-900/90 p-4 flex items-center justify-center duration-300 opacity-0 pointer-events-none'}>
            <div className="rounded bg-emerald-600 p-2">
                <ContactForm showCancel={true} callback={()=>{
                    setContactShow(false);
                }}/>
            </div>
            
        </motion.div>
        </>
        
    );
}